import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Particle from '../Particle';
import logo from '../../Assets/logo.png';
import Aboutcard from './AboutCard';

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: 'center', padding: '10px' }}>
          <Col
            md={7}
            style={{
              justifyContent: 'center',
              paddingTop: '30px',
              paddingBottom: '50px',
            }}
          >
            <h1 style={{ fontSize: '2.1em', paddingBottom: '20px' }}>
              About <strong className="purple">Us</strong>
            </h1>
            <img src={logo} alt="vejetree-logo" width={250} />
          </Col>
          <Aboutcard />
        </Row>
      </Container>
    </Container>
  );
}

export default About;
