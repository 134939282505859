import React from 'react';
import Ticker from 'framer-motion-ticker';
import Step1 from '../Assets/Projects/HD/Castor-Oil-Step-1.webp';
import Step2 from '../Assets/Projects/HD/Castor-Oil-Step-2.webp';
import BiotinConditioner from '../Assets/Projects/HD/Biotin-Collagen-Conditioner.webp';
import BiotinShampoo from '../Assets/Projects/HD/Biotin-Collagen-Shampoo.webp';
import CastorOilShampoo from '../Assets/Projects/HD/Castor-Oil-Shampoo.webp';
import CastorOilConditioner from '../Assets/Projects/HD/Castor-Oil-Conditioner.webp';
import CurlyHairConditioner from '../Assets/Projects/HD/Curly-Hair-Free-Conditioner.webp';
import CurlyHairShampoo from '../Assets/Projects/HD/Curly-Hair-Free-Shampoo.webp';
import HairMask from '../Assets/Projects/HD/Castor-Oil-Hair-Mask.webp';
import OilyHairDandruffConditioner from '../Assets/Projects/HD/Oily-Hair-Dandruff-Conditioner.webp';
import OilyHairDandruffShampoo from '../Assets/Projects/HD/Oily-Hair-Dandruff-Shampoo.webp';
import ProteinCollagen from '../Assets/Projects/HD/Protein-Collagen Complex.webp';

function Tiker() {
  const images = [
    BiotinShampoo,
    BiotinConditioner,
    CurlyHairShampoo,
    CurlyHairConditioner,
    OilyHairDandruffShampoo,
    OilyHairDandruffConditioner,
    CastorOilShampoo,
    CastorOilConditioner,
    HairMask,
    Step1,
    Step2,
    ProteinCollagen,
  ];
  const titles = [
    'Biotin & Collagen Shampoo',
    'Biotin & Collagen Conditioner',
    'Curly Hair Free Shampoo',
    'Curly Hair Free Conditioner',
    'Oily Hair Dandruff Shampoo',
    'Oily Hair Dandruff Conditioner',
    'Castor Oil Shampoo',
    'Castor Oil Conditioner',
    'Royal Water Treatment',
    'Castor Oil Step 1',
    'Castor Oil Step 2',
    'Protein Collagen Complex',
  ];

  return (
    <div className="App ">
      <Ticker duration={20}>
        {images.map((item, index) => (
          <div
            key={index}
            style={{
              position: 'relative',
              marginLeft: '1rem',
              marginRight: '1rem',
            }}
          >
            <img
              src={item}
              alt={`Step ${index + 1}`}
              style={{
                backgroundColor: 'white',
                margin: '5px',
                width: '200px',
                borderRadius: '2rem',
              }}
            />

            <p
              className="gold"
              style={{
                position: 'relative',
                bottom: 0,
                width: '100%',
                textAlign: 'center',
              }}
            >
              {titles[index]}
            </p>
          </div>
        ))}
      </Ticker>
    </div>
  );
}
export default Tiker;
