import Carousel from 'react-bootstrap/Carousel';

import OilyHairDandruffConditioner from '../../Assets/Projects/HD/Oily-Hair-Dandruff-Conditioner.webp';
import OilyHairDandruffShampoo from '../../Assets/Projects/HD/Oily-Hair-Dandruff-Shampoo.webp';

function CarouselFadeOliy() {
  const customCarouselItemStyle = {
    display: 'flex',
    flexDirection: 'row', // Display in a row layout
    alignItems: 'center', // Center vertically
  };

  const customImageStyle = {
    marginRight: '10px', // Create some space between the image and caption
  };

  const customCaptionStyle = {
    flex: '1', // Take up remaining horizontal space

    borderBottomLeftRadius: '80px 80px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '10px',
    marginLeft: '-50px',
    borderLeftRadius: '20rem', // Overlap the image slightly to the left
  };

  return (
    <Carousel fade controls={false} indicators={false}>
      <Carousel.Item style={customCarouselItemStyle}>
        <img
          src={OilyHairDandruffShampoo}
          alt="OilyHairDandruffShampoo"
          width={200}
          height={300}
          className="image-border-glow-gr border-rounded p-2 m-2"
          style={customImageStyle}
          loading="lazy"
        />
        <div style={customCaptionStyle}>
          <h3>Oily Hair Dandruff Shampoo</h3>
          <p>
            Nourishing, color-safe formula Delivers a luxe lather and light
            tropical scent Helps eliminate dandruff, itchiness and scalp dryness
          </p>
        </div>
      </Carousel.Item>
      <Carousel.Item style={customCarouselItemStyle}>
        <img
          src={OilyHairDandruffConditioner}
          alt=""
          width={200}
          height={300}
          className="image-border-glow-gr border-rounded p-2 m-2"
          style={customImageStyle}
          loading="lazy"
        />
        <div style={customCaptionStyle}>
          <h3>Oily Hair Dandruff Conditioner</h3>
          <p>
            Nourishing, color-safe formula Delivers a luxe lather and light
            tropical scent Helps eliminate dandruff, itchiness and scalp dryness
          </p>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFadeOliy;
