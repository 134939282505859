import * as React from 'react';
import Card from 'react-bootstrap/Card';
import { motion } from 'framer-motion';
import useInView from '../Projects/useInView';

const textArray = [
  'Canadian Craftsmanship: All Vejetree products are proudly manufactured in Canada, a country known for its commitment to quality and environmental sustainability. We take pride in supporting local talent and using ethically sourced ingredients.',
  "Natural Ingredients: We believe in the power of nature, and that's why our products are carefully curated with natural, plant-based ingredients that are gentle on your hair and the environment. We avoid harsh chemicals, sulfates, and parabens.",
  'Cruelty-Free: We are dedicated to the ethical treatment of animals and, as such, all Vejetree products are cruelty-free. We love animals as much as you do!',
  "Customer Satisfaction: Your hair care journey is personal, and we're here to support you every step of the way. If you have questions, concerns, or need guidance on choosing the right products, our responsive customer service team is here to help.",
  "Results You Can See and Feel: Our products are crafted with your hair's well-being in mind, and we're confident you'll notice a positive difference in the look and feel of your hair.",
];

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

function Aboutcard() {
  const [ref, inView] = useInView(); // use the hook

  return (
    <>
      <Card className="quote-card-view">
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p style={{ color: 'wheat' }}>Why Choose Vejetree? </p>
          </blockquote>
        </Card.Body>
      </Card>
      <motion.ol
        className="container roman-list flex flex-col justify-center items-center"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {textArray.map((text, index) => (
          <div className="module-border-wrap m-2" key={text}>
            <motion.li
              key={index}
              className="item text-left module "
              variants={item}
            >
              {text}
            </motion.li>
          </div>
        ))}
      </motion.ol>
      <div>
        <p>
          Vejetree, your trusted source for premium hair care products that are
          as unique as your beautiful locks. We take pride in offering a range
          of nourishing solutions, all meticulously crafted and manufactured in
          the heart of Canada. Our mission is simple: to help you achieve the
          hair of your dreams through the power of nature and innovation.
        </p>
      </div>
    </>
  );
}
export default Aboutcard;
