import React from 'react';
import Card from 'react-bootstrap/Card';

import useInView from './useInView';
function ProjectCards(props) {
  const [ref, inView] = useInView(); // use the hook

  return (
    <section
      ref={ref}
      className={`animate__animated ${
        inView ? 'animate__fadeIn' : 'animate__fadeOut'
      }`}
    >
      <Card className="project-card-view flex-row flex-wrap">
        <div
          ref={ref}
          className={`animate__animated ${
            inView ? 'animate__zoomIn' : 'animate__zoomOut'
          }`}
        >
          {/* <Card.Img variant="top" src={props.imgPath} alt="card-img" /> */}
          <props.comp {...props} />
        </div>

        <Card.Body>
          <div
            ref={ref}
            className={`animate__animated ${
              inView ? 'animate__zoomIn' : 'animate__zoomOut'
            }`}
          >
            {/* <Card.Title>{props.title}</Card.Title> */}
          </div>
          <Card.Text
            style={{ textAlign: 'justify' }}
            ref={ref}
            className={`animate__animated ${
              inView ? 'animate__flipInX' : 'animate__fadeOut'
            }`}
          >
            {props.description}
          </Card.Text>
          {/* <Button variant="primary" href={props.ghLink} target="_blank">
            <ReadMoreOutlinedIcon /> &nbsp; Learn More
          </Button> */}
          {'\n'}
          {'\n'}
        </Card.Body>
      </Card>
    </section>
  );
}
export default ProjectCards;
