import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import Particle from "../Particle";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import logo from "../../Assets/logo.png";
import useInView from "../Projects/useInView";
import { useForm, ValidationError } from "@formspree/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";

const ContactForm = () => {
  // Google analytics
  useEffect(() => {
    ReactGA.initialize("G-ZB3R3BTMD2");
    ReactGA.pageview(window.location.pathname);
  }, []);
  // Google analytics end
  const [ref, inView] = useInView();
  const [state, handleSubmit] = useForm("maygarnn");
  const isEmpty = (str) => !str || !str.trim();
  window.onbeforeunload = () => {
    for (const form of document.getElementsByTagName("form")) {
      form.reset();
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
        className="my-toast-container"
      />
      <Particle />
      <section className="text-white p-4 form-section ">
        <div
          ref={ref}
          className={`animate__animated text-center ${
            inView ? "animate__fadeInDown" : "animate__fadeOut"
          }`}
        >
          <h1>Contact us</h1>

          <p className="w-responsive mx-auto mb-5 m-2">
            Do you have any questions? Please do not hesitate to contact us
            directly. Our team will come back to you within a matter of hours to
            help you.
          </p>
        </div>
        <div className="row m-0">
          <div className="col-md-8 mb-md-0 mb-5 pb-4">
            <form
              onSubmit={handleSubmit}
              ref={ref}
              className={`animate__animated row justify-content-center ${
                inView ? "animate__fadeInDown" : "animate__fadeOut"
              }`}
              id="contact-form"
              name="contact-form"
              action="mail.php"
              method="POST"
            >
              <div
                ref={ref}
                className={`animate__animated row mb-2 ${
                  inView ? "animate__fadeInDown" : "animate__fadeOut"
                }`}
              >
                <div className="col-md-6 py-2 ">
                  <TextField
                    required
                    className="image-border-glow-full"
                    id="name"
                    name="name"
                    label="Your name"
                    fullWidth
                    variant="filled"
                    sx={{
                      bgcolor: "#dee2e654",
                      color: "#fff", // Change the background color
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                  />
                  <ValidationError
                    prefix="Name"
                    field="name"
                    errors={state.errors}
                  />
                </div>

                <div className="col-md-6 py-2">
                  <TextField
                    required
                    className="image-border-glow-full"
                    id="email"
                    name="email"
                    label="Your email"
                    fullWidth
                    variant="filled"
                    sx={{
                      bgcolor: "#dee2e654", // Change the background color
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                  />
                </div>
              </div>

              <div
                ref={ref}
                className={`animate__animated row mb-2 ${
                  inView ? "animate__fadeInUp" : "animate__fadeOut"
                }`}
              >
                <div className="col-md-12 py-2">
                  <TextField
                    required
                    className="image-border-glow-full"
                    id="subject"
                    name="subject"
                    label="Subject"
                    fullWidth
                    variant="filled"
                    sx={{
                      bgcolor: "#dee2e654", // Change the background color
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-md-12 py-2">
                  <TextField
                    required
                    className="image-border-glow-full"
                    id="message"
                    name="message"
                    label="Your message"
                    multiline
                    rows={4}
                    fullWidth
                    variant="filled"
                    sx={{
                      bgcolor: "#dee2e654", // Change the background color
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                  />
                </div>
              </div>
              <div className="text-center text-md-left">
                <button
                  className="btn-hover color-3"
                  type="submit"
                  disabled={state.submitting}
                  onClick={() => {
                    const name = document.getElementById("name").value;
                    const email = document.getElementById("email").value;
                    const subject = document.getElementById("subject").value;
                    const message = document.getElementById("message").value;

                    if (
                      isEmpty(name) ||
                      isEmpty(email) ||
                      isEmpty(subject) ||
                      isEmpty(message)
                    ) {
                      toast.error("Please fill all fields before submitting.");
                    } else if (state.succeeded) {
                      for (const form of document.getElementsByTagName(
                        "form"
                      )) {
                        form.reset();
                      }
                      window.onbeforeunload = null;
                      toast.success(
                        "Your message had been received we will get back to you shortly!!"
                      );
                    }
                  }}
                >
                  Submit <SendOutlinedIcon />
                </button>{" "}
              </div>
            </form>

            <div className="status"></div>
          </div>

          <div className="col-md-4 text-center">
            <ul className="list-unstyled mb-0">
              <li>
                <img src={logo} alt="Vejetree-logo" width={200} />
              </li>
              <li>
                <i className="fas fa-map-marker-alt fa-2x"></i>
                <br />
                <br />
                <p>
                  Toronto, Ontario <br /> M4B 2J8, Canada
                </p>
              </li>

              <li>
                <i className="fas fa-phone mt-4 fa-2x"></i>
                <br />
                <br />
              </li>

              <li>
                <i className="fas fa-envelope mt-4 fa-2x"></i>
                <p>info@vejetree.com</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
