import Carousel from 'react-bootstrap/Carousel';

import BiotinConditioner from '../../Assets/Projects/HD/Biotin-Collagen-Conditioner.webp';
import BiotinShampoo from '../../Assets/Projects/HD/Biotin-Collagen-Shampoo.webp';

function CarouselFadeBiotin() {
  const customCarouselItemStyle = {
    display: 'flex',
    flexDirection: 'row', // Display in a row layout
    alignItems: 'center', // Center vertically
  };

  const customImageStyle = {
    marginRight: '10px', // Create some space between the image and caption
  };

  const customCaptionStyle = {
    flex: '1', // Take up remaining horizontal space

    borderBottomLeftRadius: '80px 80px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '10px',
    marginLeft: '-50px',
    borderLeftRadius: '20rem', // Overlap the image slightly to the left
  };

  return (
    <Carousel fade controls={false} indicators={false}>
      <Carousel.Item style={customCarouselItemStyle}>
        <img
          src={BiotinConditioner}
          alt=""
          width={200}
          height={300}
          className="image-border-glow border-rounded p-2 m-2"
          style={customImageStyle}
          loading="lazy"
        />
        <div style={customCaptionStyle}>
          <h3>Biotin Collagen Conditioner</h3>
          <p>
            Repairs Damaged Hair Recovers Hair Structure Boosts Strength&volume
            Smoothing Hair
          </p>
        </div>
      </Carousel.Item>
      <Carousel.Item style={customCarouselItemStyle}>
        <img
          src={BiotinShampoo}
          alt=""
          width={200}
          height={300}
          className="image-border-glow border-rounded p-2 m-2"
          style={customImageStyle}
          loading="lazy"
        />
        <div style={customCaptionStyle}>
          <h3>Biotin Collagen Shampoo</h3>
          <p>
            Repairs Damaged Hair Recovers Hair Structure Boosts Strength&volume
            Smoothing Hair
          </p>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFadeBiotin;
