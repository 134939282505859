import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer absolute ">
      <Row>
        <Col md="12" className="footer-copywright">
          <h3>Copyright ©{year} VEJETREE - All Rights Reserved.</h3>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
