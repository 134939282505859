import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProjectCard from './ProjectCards';
import Particle from '../Particle';
import '../../style.css';
import 'animate.css';
import CarouselFadeBiotin from '../Carousels/ImageCarouselBiotin';
import CarouselFadeCastor from '../Carousels/ImageCarouselCastor';
import CarouselFadeCurly from '../Carousels/ImageCarouselCurlyHair';
import CarouselFadeOliy from '../Carousels/ImageCarouselOliyHair';
import CarouselFadeHairMask from '../Carousels/ImageCarouselHairMask';
import CarouselFadeProteinCollagen from '../Carousels/ImageCarouselProteinCollagen';

import ScrollVelocity from '../scroll-velocity';

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our Latest <strong className="purple">Products </strong>
        </h1>
        <ScrollVelocity />
        <Row style={{ justifyContent: 'center', paddingBottom: '10px' }}>
          <Col md={6} className="project-card">
            <ProjectCard
              comp={CarouselFadeBiotin}
              // imgPath={biotin}
              isBlog={false}
              title="Biotin Collagen Conditioner"
              description="1000ml, 33.8 fl.oz. Repairs Damaged Hair Recovers Hair Structure Boosts Strength&volume
            Smoothing Hair"
              ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://chatify-49.web.app/"
            />
          </Col>
          <Col md={6} className="project-card">
            <ProjectCard
              comp={CarouselFadeCurly}
              // imgPath={editor}
              isBlog={false}
              title="Editor.io"
              description="1000ml, 33.8 fl.oz. For Curly Hair Developed to Condition & Enhance Curls Natural"
              ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://editor.soumya-jit.tech/"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              comp={CarouselFadeCastor}
              // imgPath={bitsOfCode}
              isBlog={false}
              title="Bits-0f-C0de"
              description="1000ml, 33.8 fl.oz. Contains cold pressed castor oil ingredients to nourish and
            strengthen your hair"
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              comp={CarouselFadeOliy}
              // imgPath={leaf}
              isBlog={false}
              title="Oily"
              description="1000ml, 33.8 fl.oz. Nourishing, color-safe formula Delivers a luxe lather and light
            tropical scent eliminate dandruff, itchiness and scalp dryness"
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>
          <Col md={6} className="project-card">
            <ProjectCard
              comp={CarouselFadeHairMask}
              // imgPath={leaf}
              isBlog={false}
              title="Oily"
              description="500ml 16.9 f .oz. Strength & rebuild & repair your hair Contains castor oil,relieve
            dryness and deeply hydrate both hair shafts and scalp."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>
          <Col md={6} className="project-card">
            <ProjectCard
              comp={CarouselFadeProteinCollagen}
              // imgPath={leaf}
              isBlog={false}
              title="Oily"
              description="A Unique, Previous Blend Of Protein And Collagen. Which Instantly
            Nourish Hair For Increase Silky And Shine"
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
