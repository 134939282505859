import React from 'react';
import Projects from '../Projects/Projects';
// import Video from './Video';
import Tiker from '../framerTikeer';
import ContactForm from '../Contact/ContactForm';
function Home() {
  return (
    <section>
      <div className="vidOuterout ">{/* <Video /> */}</div>
      <div className="faded-shadow-div">
        <Tiker />
      </div>
      {/* <ScrollVelocity /> */}
      <Projects />
      <ContactForm />
      {/* <Particle /> */}
    </section>
  );
}

export default Home;
